import { useParams } from 'react-router-dom';
import { useState, useEffect, ChangeEvent } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase.config';

import { makeStyles, Theme, Grid } from '@material-ui/core';

import AccountMenu from '../AccountMenu/AccountMenu';
import JoinRoomButton from '../JoinRoomButton/JoinRoomButton';
import JobFeed from '../JobFeed/JobFeed';
import AddTextItemForm from '../AddTextItemForm/AddTextItemForm';
import AddAIItemForm from '../AddAIItemForm/AddAIItemForm';
import AddImageItemForm from '../AddImageItemForm/AddImageItemForm';
import { onAuthStateChanged, User } from 'firebase/auth';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
    top: '50px',
    '& .icon': {
      width: '70px',
      margin: '0 10px',
      fontSize: '10px',
      textAlign: 'center',
      '& img': {
        width: '50px',
      },
    },
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    height: '700px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '3em 4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  account: {
    display: 'block',
    width: '500px',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '20px',
    color: 'white',
    textAlign: 'right',
    '& a': {
      color: 'white',
      padding: '20px',
    },
  },
  jobFeedContainer: {
    maxHeight: '400px', // Adjust this value according to your design
    overflowY: 'auto', // Enables vertical scrolling
  },
}));

enum FormType {
  None,
  Text,
  ImageOrVideo,
  AI,
}

function JobPage() {
  const { jobId } = useParams<{ jobId: string }>();
  const [jobData, setJobData] = useState<any>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userName, setUserName] = useState('');
  const [activeForm, setActiveForm] = useState<FormType>(FormType.None);

  // Define an enum for the forms

  const classes = useStyles();

  useEffect(() => {
    const authStateListener = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        console.log('uid', user.uid);
      } else {
        console.log('user is logged out');
      }
    });

    return () => {
      authStateListener();
    };
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      const docRef = doc(db, 'job', jobId);
      const jobSnapshot = await getDoc(docRef);

      if (jobSnapshot.exists()) {
        const jobData = jobSnapshot.data();
        setJobData(jobData);

        // Fetch user's name using userId from the job data
        const userDoc = await getDoc(doc(db, 'users', jobData.userId));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name);
        }
      }
    };

    fetchJob();
  }, [jobId]);

  if (!jobData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.background}>
      <AccountMenu />
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="card-white">
                    <div className="card-body">
                      <h1>{jobData.job}</h1>
                      <p>Address: {jobData.address}</p>
                      <p>Date: {new Date(jobData.timestamp?.seconds * 1000).toLocaleString()}</p>
                      <p>Created by: {userName}</p>
                      <hr />
                      <div style={{ display: 'flex' }}>
                        <JoinRoomButton
                          jobId={jobId}
                          roomName={jobData.job}
                          displayName={user?.displayName || 'Not logged in'}
                          showIcon={true}
                        />
                        <div
                          className="icon"
                          onClick={() =>
                            setActiveForm(prev => (prev === FormType.Text ? FormType.None : FormType.Text))
                          }
                        >
                          <img src="https://blue-stream-files.s3.amazonaws.com/text.jpg" />
                          <br />
                          Add Note
                        </div>
                        <div
                          className="icon"
                          onClick={() =>
                            setActiveForm(prev =>
                              prev === FormType.ImageOrVideo ? FormType.None : FormType.ImageOrVideo
                            )
                          }
                        >
                          <img src="https://blue-stream-files.s3.amazonaws.com/photo.jpg" />
                          <br />
                          Add Image or Video
                        </div>
                        <div
                          className="icon"
                          onClick={() => setActiveForm(prev => (prev === FormType.AI ? FormType.None : FormType.AI))}
                        >
                          <img src="https://blue-stream-files.s3.amazonaws.com/brain.png" />
                          <br />
                          Ask AI
                        </div>
                      </div>
                      {/* Render the form for adding new text items */}
                      {activeForm === FormType.Text && user && <AddTextItemForm jobId={jobId} userId={user.uid} />}
                      {/* Render the form for adding new image items */}
                      {activeForm === FormType.ImageOrVideo && user && (
                        <AddImageItemForm jobId={jobId} userId={user.uid} />
                      )}
                      {/* Render the form for adding new text items */}
                      {activeForm === FormType.AI && user && <AddAIItemForm jobId={jobId} userId={user.uid} />}
                      {/* Render the feed items */}
                      <hr />
                      <div className={classes.jobFeedContainer}>
                        <JobFeed jobId={jobId} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPage;
