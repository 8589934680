import React, { useState } from 'react';
import { db, Timestamp } from '../../services/firebase.config';
import { collection, addDoc } from 'firebase/firestore';
import { Button, TextField, makeStyles, Theme } from '@material-ui/core';

type AddTextItemFormProps = {
  jobId: string;
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em 0 3.5em',
  },
  textFieldContainer: {
    width: '100%',
    margin: '1em 0',
  },
  continueButton: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function AddTextItemForm({ jobId, userId }: AddTextItemFormProps) {
  const [text, setText] = useState<string>('');
  const classes = useStyles();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (text.trim() === '') return;

    const feedCollection = collection(db, 'feed');

    const feedItem = {
      jobId,
      userId: userId,
      type: 'text',
      content: text,
      timestamp: Timestamp.fromDate(new Date()),
    };

    await addDoc(feedCollection, feedItem);
    setText('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputContainer}>
        <div className={classes.textFieldContainer}>
          <TextField
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="Add a note..."
            variant="outlined"
            multiline
            rows={4} // specify the number of rows you want or adjust as needed
            fullWidth
          />
        </div>

        <Button type="submit" variant="contained" color="secondary" className={classes.continueButton}>
          Save Note
        </Button>
      </div>
    </form>
  );
}

export default AddTextItemForm;
