import React, { useState, useEffect, ChangeEvent } from 'react';
import { makeStyles, Theme, Grid } from '@material-ui/core';

import AccountMenu from '../AccountMenu/AccountMenu';
import JoinRoomButton from '../JoinRoomButton/JoinRoomButton';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, getDocs, doc, deleteDoc, orderBy, query } from 'firebase/firestore';
import { auth, db } from '../../services/firebase.config';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
    top: '50px',
  },
  innerContainer: {
    display: 'flex',
    width: '888px', // Desktop width
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'auto',
    position: 'relative',
    height: '700px',
    margin: 'auto',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Mobile full-width
    },
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '3em 4em', // Desktop padding
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em', // Mobile padding
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  account: {
    display: 'block',
    width: '500px',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '20px',
    color: 'white',
    textAlign: 'right',
    '& a': {
      color: 'white',
      padding: '20px',
    },
  },
}));

const JobList = () => {
  const [jobs, setJob] = useState<{ id: string; [key: string]: any }[]>([]);
  const collectionRef = collection(db, 'job');
  const [createJob, setCreateJob] = useState('');
  const [jobAddress, setJobAddress] = useState('');

  const classes = useStyles();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const authStateListener = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        console.log('uid', user.uid);
      } else {
        console.log('user is logged out');
      }
    });

    return () => {
      authStateListener();
    };
  }, []);

  //Add Job Handler
  const submitJob = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      alert('Please login before creating a job');
      return;
    }

    try {
      await addDoc(collectionRef, {
        job: createJob,
        address: jobAddress,
        userId: user?.uid, // associate the job with the user's ID
        isChecked: false,
        timestamp: serverTimestamp(),
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getJob = async () => {
      const q = query(collectionRef, orderBy('timestamp', 'desc'));
      await getDocs(q)
        .then(job => {
          let jobData = job.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setJob(jobData);
        })
        .catch(err => {
          console.log(err);
        });
    };
    getJob();
  }, []);

  //Delete Handler
  const deleteJob = async (id: string) => {
    try {
      console.log(id);
      // Confirm if the user really wants to delete the job
      const userConfirmed = window.confirm('Are you sure you want to delete this Job?');

      // If the user clicks "OK" (userConfirmed is true), delete the job
      if (userConfirmed) {
        const documentRef = doc(db, 'job', id);
        await deleteDoc(documentRef);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.background}>
      <AccountMenu />
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content} style={{ maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="card-white">
                    <div className="card-body">
                      <button data-bs-toggle="modal" data-bs-target="#addModal" type="button" className="btn btn-info">
                        + New Job
                      </button>

                      {jobs.map(({ job, address, id, timestamp }) => (
                        <div className="job-list" key={id}>
                          <div className="job-item">
                            <hr />
                            <button type="button" className="btn btn-danger float-end" onClick={() => deleteJob(id)}>
                              Delete
                            </button>
                            <Link to={`/job/${id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                              <div className="checker">
                                <h6>{job}</h6>
                                <p>{address}</p>
                              </div>
                              <i>{new Date(timestamp.seconds * 1000).toLocaleString()}</i>
                              <br />
                              <br />
                            </Link>
                            <span className=" float-end mx-3"></span>
                            <Grid item md={5} sm={12} xs={12}>
                              <JoinRoomButton
                                jobId={id}
                                roomName={job}
                                displayName={user?.displayName || 'Not logged in'}
                                showIcon={false}
                              />
                            </Grid>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="addModal" tabIndex={-1} aria-labelledby="addModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <form className="d-flex" onSubmit={submitJob}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="addModalLabel">
                        Create New Job
                      </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Job Name"
                        onChange={e => setCreateJob(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control my-2"
                        placeholder="Job Address"
                        onChange={e => setJobAddress(e.target.value)}
                      />
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>

                      <button className="btn btn-primary">Create job</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
