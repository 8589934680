import React, { useState } from 'react';
import { db, Timestamp } from '../../services/firebase.config';
import { collection, addDoc } from 'firebase/firestore';
import axios from 'axios';
import { Button, TextField, makeStyles, Theme } from '@material-ui/core';
import { ChaoticOrbit } from '@uiball/loaders';

type AddAIItemFormProps = {
  jobId: string;
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '1.5em 0 3.5em',
  },
  textFieldContainer: {
    width: '100%',
    margin: '1em 0',
  },
  continueButton: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function AddAIItemForm({ jobId, userId }: AddAIItemFormProps) {
  const [question, setQuestion] = useState<string>('');
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (question.trim() === '') return;
    setIsSubmitting(true); // Start submission

    const payload = {
      query: question,
    };

    axios
      .post('https://us-west1-blue-stream-394523.cloudfunctions.net/python-http-function', payload)
      .then(async response => {
        const answer = response.data.answer;
        console.log('Answer: ' + answer);
        const feedCollection = collection(db, 'feed');

        const text = `Question: ${question} \nAnswer: ${answer}`;

        const feedItem = {
          jobId,
          userId: userId,
          type: 'AIqna',
          content: text,
          timestamp: Timestamp.fromDate(new Date()),
        };

        await addDoc(feedCollection, feedItem);
        setQuestion('');
      })
      .catch(error => {
        console.error('Error posting data:', error);
      })
      .finally(() => {
        setIsSubmitting(false); // End submission
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputContainer}>
        {!isSubmitting ? (
          <>
            <div className={classes.textFieldContainer}>
              <TextField
                value={question}
                onChange={e => setQuestion(e.target.value)}
                placeholder="Ask a question..."
                variant="outlined"
                fullWidth
              />
            </div>
            <Button type="submit" variant="contained" color="secondary" className={classes.continueButton}>
              Ask
            </Button>
          </>
        ) : (
          <ChaoticOrbit size={35} color="#f50057" />
        )}
      </div>
    </form>
  );
}

export default AddAIItemForm;
