import { useState, useEffect, useRef } from 'react';
import { db, Timestamp } from '../../services/firebase.config';
import { collection, query, where, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { makeStyles, Theme } from '@material-ui/core';
import OCR from '../OCR/OCR';
import OCR_openAI from '../OCR/OCR_openAI';

type JobFeedProps = {
  jobId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatBubble: {
    padding: '10px 20px',
    borderRadius: '20px',
    marginBottom: '10px',
    display: 'block', // Adjust this to 'block' from 'inline-block'
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  questionBubble: {
    backgroundColor: '#007aff',
    color: 'white',
    borderBottomRightRadius: '5px',
    float: 'right', // Float to the right
  },
  answerBubble: {
    backgroundColor: '#e5e5ea',
    borderBottomLeftRadius: '5px',
    clear: 'both', // Make sure it appears below the question bubble
  },
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
    },
  },
}));

function JobFeed({ jobId }: JobFeedProps) {
  const [items, setItems] = useState<FeedItem[]>([]);
  const [displayNameMapping, setDisplayNameMapping] = useState<Record<string, string>>({});
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  type FeedItem = {
    id: string;
    jobId: string;
    userId: string;
    type: string;
    content: string;
    url: string;
    timestamp: Timestamp; // or whatever type you use for timestamp
  };

  async function fetchDisplayNamesForUserIds(userIds: string[]): Promise<Record<string, string>> {
    const userDocs = await Promise.all(userIds.map(userId => doc(db, 'users', userId)));

    const userSnapshots = await Promise.all(userDocs.map(getDoc));

    const userIdToDisplayName: Record<string, string> = {};
    userSnapshots.forEach((snapshot, index) => {
      if (snapshot.exists()) {
        userIdToDisplayName[userIds[index]] = snapshot.data().name;
      }
    });

    return userIdToDisplayName;
  }

  useEffect(() => {
    const feedCollection = collection(db, 'feed');

    // Set up your query
    const feedQuery = query(feedCollection, where('jobId', '==', jobId), orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(feedQuery, snapshot => {
      const fetchedItems = snapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as Omit<FeedItem, 'id'>), // Casting data to the desired shape minus the 'id' property
      }));
      setItems(fetchedItems);
      console.log('Number of items: ' + fetchedItems.length);

      const uniqueUserIds = [...new Set(fetchedItems.map(item => item.userId))];

      fetchDisplayNamesForUserIds(uniqueUserIds).then(userIdToDisplayName => {
        setDisplayNameMapping(userIdToDisplayName);
        console.log(displayNameMapping);
        console.log(uniqueUserIds);
      });
    });

    // Cleanup
    return () => unsubscribe();
  }, [jobId]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [items]);

  return (
    <div ref={scrollContainerRef}>
      {items.map(item => (
        <div key={item.id} style={{ padding: '15px 0 0 0' }}>
          {item.type === 'text' && <div>{item.content}</div>}
          {item.type === 'AIqna' && (
            <div>
              <div className={`${classes.chatBubble} ${classes.questionBubble}`}>
                {item.content
                  .split('Answer:')[0]
                  .replace('Question:', '')
                  .trim()}
              </div>
              <div className={`${classes.chatBubble} ${classes.answerBubble}`}>
                {item.content.split('Answer:')[1].trim()}
              </div>
            </div>
          )}
          {item.type === 'image' && (
            <div className={`${classes.imageContainer}`}>
              <OCR_openAI itemID={`${item.id}`} imageURL={item.url} content={item.content} />
              <img id={`img${item.id}`} src={item.url} style={{ width: '100%', height: 'auto' }} />
            </div>
          )}
          {item.type === 'video' && (
            <video width="100%" height="auto" controls>
              <source src={item.url} type="video/mp4" /> Your browser does not support the video tag.
            </video>
          )}
          {item.type === 'videoLivestream' && (
            <div>
              <span> Livestream video: </span>
              <video width="100%" height="auto" controls>
                <source src={item.url} type="video/mp4" /> Your browser does not support the video tag.
              </video>
            </div>
          )}
          <div style={{ fontSize: '10px', color: '#aaaaaa' }}>
            <span>{displayNameMapping[item.userId] || item.userId}</span> |{' '}
            <span>{new Date(item.timestamp?.seconds * 1000).toLocaleString()}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default JobFeed;
