import React, { useState } from 'react';
import { InputLabel, Theme, TextField, Grid, Button } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, NavLink } from 'react-router-dom';

import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../../services/firebase.config';
import {
  collection,
  setDoc,
  serverTimestamp,
  getDoc,
  doc,
  deleteDoc,
  runTransaction,
  orderBy,
  query,
} from 'firebase/firestore';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
    margin: '20px 0',
    '& label': {
      margin: '15px 0',
    },
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Signup = () => {
  const history = useHistory();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const user = await createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        // Update the user's display name.
        updateProfile(user, {
          displayName: userName,
        });

        if (user) {
          const userRef = doc(db, 'users', user.uid);
          getDoc(userRef).then(docSnapshot => {
            if (!docSnapshot.exists()) {
              // If user doesn't exist in Firestore, add them
              setDoc(userRef, {
                uid: user.uid,
                name: user.displayName,
                email: user.email,
              });
            }
          });
        }
        setError(null); // Clear error message on successful signup
        history.replace('/login');
        // ...
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setError(errorMessage);
        // ..
      });
  };

  return (
    <IntroContainer>
      <section>
        <div>
          <div>
            <form onSubmit={onSubmit}>
              <div>
                <div className={classes.textFieldContainer}>
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <TextField
                    type="text"
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    required
                    placeholder="Username"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <InputLabel htmlFor="email-address">Email address</InputLabel>
                  <TextField
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    placeholder="Email address"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>

                <div className={classes.textFieldContainer}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <TextField
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    placeholder="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>
              </div>
              <Grid container justifyContent="flex-end">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Button type="submit" variant="contained" color="primary" className={classes.continueButton}>
                  Sign up
                </Button>
              </Grid>
            </form>
            <p>
              Already have an account? <NavLink to="/login">Sign in</NavLink>
            </p>
          </div>
        </div>
      </section>
    </IntroContainer>
  );
};

export default Signup;
