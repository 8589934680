import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { InputLabel, Theme, TextField, Grid, Button } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, NavLink } from 'react-router-dom';
import { auth } from '../../services/firebase.config';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
    '& label': {
      margin: '20px 0',
    },
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user;
        history.replace('/home');
        console.log(user);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <IntroContainer>
      <section>
        <div>
          <div>
            <form onSubmit={onLogin}>
              <div className={classes.inputContainer}>
                <div className={classes.textFieldContainer}>
                  <InputLabel htmlFor="email-address">Email address</InputLabel>
                  <TextField
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    placeholder="Email address"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>

                <div className={classes.textFieldContainer}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <TextField
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    placeholder="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>
              </div>
              <Grid container justifyContent="flex-end">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Button type="submit" variant="contained" color="secondary" className={classes.continueButton}>
                  Login
                </Button>
              </Grid>
            </form>
            <br />
            <p>
              Don't have an account yet? <NavLink to="/signup">Sign Up!</NavLink>
            </p>
          </div>
        </div>
      </section>
    </IntroContainer>
  );
};

export default Login;
