import { useState, useEffect } from 'react';
import { createWorker } from 'tesseract.js';
//import "./App.css";

const useOCR = () => {
  const [ocr, setOcr] = useState<string>('');
  const [workerInstance, setWorkerInstance] = useState<Tesseract.Worker | null>(null);

  useEffect(() => {
    const instantiateWorker = async () => {
      const worker = await createWorker();
      setWorkerInstance(worker);
    };

    instantiateWorker();
  }, []);

  const initializeWorker = async () => {
    if (!workerInstance) return;
    await workerInstance.load();
    // If loadLanguage doesn't exist, comment out the next line
    // await workerInstance.loadLanguage('eng');
    await workerInstance.reinitialize('eng');
  };

  const convertImageToText = async (imageData: string) => {
    if (!imageData || !workerInstance) return;
    const {
      data: { text },
    } = await workerInstance.recognize(imageData);
    setOcr(text);
  };

  return { ocr, initializeWorker, convertImageToText };
};

function App() {
  const [imageData, setImageData] = useState<string | null>(null);
  const { ocr, initializeWorker, convertImageToText } = useOCR();

  useEffect(() => {
    initializeWorker();
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageData(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imageData) {
      convertImageToText(imageData);
    }
  }, [imageData]);

  return (
    <div className="App">
      <div>
        <p>Choose an Image</p>
        <input type="file" onChange={handleImageChange} accept="image/*" />
      </div>
      <div className="display-flex">
        <img src={imageData || undefined} alt="Uploaded preview" />
        <p>The text: {ocr}</p>
      </div>
    </div>
  );
}

export default App;
