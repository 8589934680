import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBjitUrPYnncTPQjPE1tsxWQwLdOoBQgds',
  authDomain: 'react-todo-379ae.firebaseapp.com',
  projectId: 'react-todo-379ae',
  storageBucket: 'react-todo-379ae.appspot.com',
  messagingSenderId: '648527296998',
  appId: '1:648527296998:web:72b8626d6429fd81857e27',
  measurementId: 'G-DSJXVLV3DC',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
export { Timestamp };

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
