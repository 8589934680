import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import { signOut, onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../../services/firebase.config';

const useStyles = makeStyles((theme: Theme) => ({
  account: {
    display: 'block',
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    padding: '20px',
    color: 'white',
    textAlign: 'right',
    zIndex: 1000,
    '& a': {
      color: 'white',
      padding: '20px',
    },
  },
  home: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '20px',
    color: 'white',
    zIndex: 100,
    '& a': {
      color: 'white',
      padding: '20px',
      textDecoration: 'none',
    },
  },
}));

const AccountMenu = () => {
  const classes = useStyles();
  //const { user } = useAppState();
  const history = useHistory();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const authStateListener = auth.onAuthStateChanged(user => {
      setUser(user);
    });

    return () => {
      authStateListener();
    };
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        history.replace('/');
        console.log('Signed out successfully');
      })
      .catch(error => {
        // An error happened.
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        console.log('uid', uid);
      } else {
        // User is signed out
        // ...
        console.log('user is logged out');
      }
    });
  }, []);

  return (
    <div className={classes.account}>
      <div className={classes.home}>
        <a href="/">BLUESTREAM</a>
      </div>
      {user ? (
        <div>
          <span>{user.displayName}</span>
          <a href="#" onClick={handleLogout}>
            Logout
          </a>
        </div>
      ) : (
        <>
          <a href="/login">Login</a>
        </>
      )}
    </div>
  );
};

export default AccountMenu;
