import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, Grid } from '@material-ui/core';

interface RoomParticipantCountProps {
  jobId: string;
  roomName: string;
  displayName: string;
  showIcon: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  joinButtons: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: 'auto',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  activeButton: {
    backgroundColor: '#02FAA5',
  },
  hiddenButton: {
    display: 'none',
  },
}));

const JoinRoomButton: React.FC<RoomParticipantCountProps> = ({ jobId, roomName, displayName, showIcon }) => {
  const [count, setCount] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const fetchParticipantCount = async () => {
      try {
        const response = await fetch(`/room-participants/${roomName}`);
        const data = await response.json();
        const parsedData = JSON.parse(data);
        console.log(parsedData.count);
        setCount(parsedData.count);
      } catch (error) {
        console.error('Failed to fetch participant count', error);
      }
    };

    fetchParticipantCount();
  }, [roomName]);

  const handleJoin = () => {
    const escapedRoomName = encodeURIComponent(roomName);
    const escapedDisplayName = encodeURIComponent(displayName);
    const newLocation = `/room/${jobId}/${escapedRoomName}/${escapedDisplayName}`;
    console.log(newLocation);
    history.replace(newLocation);
  };

  return (
    <div className={classes.joinButtons}>
      {showIcon && count == 0 ? (
        <div className="icon" onClick={() => handleJoin()}>
          <img src="https://blue-stream-files.s3.amazonaws.com/stream.jpg" />
          <br />
          Start Livestream
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          data-cy-join-now
          onClick={() => handleJoin()}
          className={count >= 1 ? classes.activeButton : classes.hiddenButton}
        >
          {count >= 1 ? `Join ${count} others` : 'Start New Livestream'}
        </Button>
      )}
    </div>
  );
};

export default JoinRoomButton;
