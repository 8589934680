import React, { useState, useRef } from 'react';
import axios from 'axios';
import { db, Timestamp } from '../../services/firebase.config';
import { collection, addDoc } from 'firebase/firestore';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { RESIZE_IMAGE_WIDTH } from '../../constants';

type AddImageItemFormProps = {
  jobId: string;
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em 0 3.5em',
  },
  continueButton: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fileInput: {
    display: 'none',
  },
}));

function AddImageItemForm({ jobId, userId }: AddImageItemFormProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadToS3 = async () => {
    if (!selectedImage) return;

    const fileName = encodeURIComponent(`${Date.now()}-${selectedImage.name}`);
    const fileType = selectedImage.type;

    // Request a signed URL from our server
    try {
      setUploading(true);
      const theUrl = `/generate-signed-url?filename=${fileName}&filetype=${fileType}`;
      console.log('The URL: ' + theUrl);
      const response = await axios.get(theUrl);

      const signedUrl = response.data.signedUrl;
      console.log('The signed URL: ' + signedUrl);

      // Use the signed URL to upload the image directly to S3
      await axios.put(signedUrl, selectedImage, {
        headers: {
          'Content-Type': fileType,
          //'x-amz-acl': 'public-read'  // If you're using this in the server-side generation of the signed URL
        },
      });

      alert('Image successfully saved!');
      setUploading(false);

      // Extract the public URL by removing the query string
      const publicUrl = signedUrl.split('?')[0];
      console.log('Public URL of uploaded image:', publicUrl);

      const feedCollection = collection(db, 'feed');

      let mediaType = 'image';
      if (fileType.startsWith('video/')) {
        mediaType = 'video';
      }

      const feedItem = {
        jobId,
        userId: userId,
        type: mediaType,
        url: publicUrl,
        timestamp: Timestamp.fromDate(new Date()),
      };

      await addDoc(feedCollection, feedItem);

      // Make smaller copy of image
      if (mediaType == 'image') {
        const bucketName = 'blue-stream-files';
        const resizeUrl = `https://us-west1-blue-stream-394523.cloudfunctions.net/resize_img?bucket_name=${bucketName}&file_name=${fileName}&width=${RESIZE_IMAGE_WIDTH}`;
        const resizeResponse = await axios.get(resizeUrl);
        console.log('Resize response: ' + resizeResponse.data);
      }
    } catch (err) {
      console.error('Error uploading the image: ', err);
      setUploading(false);
      alert('Failed to upload the image.');
    }
  };

  return (
    <div className={classes.inputContainer}>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,video/*"
        onChange={handleImageChange}
        className={classes.fileInput}
      />
      <Button onClick={handleButtonClick} variant="outlined">
        Choose File
      </Button>
      {selectedImage && (
        <Button
          onClick={uploadToS3}
          disabled={uploading}
          variant="contained"
          color="secondary"
          className={classes.continueButton}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
      )}
    </div>
  );
}

export default AddImageItemForm;
