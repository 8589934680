import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { db, Timestamp, auth } from '../../../services/firebase.config';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [user, setUser] = useState<User | null>(null);
  const [jobData, setJobData] = useState<any>(null);
  const { jobId } = useParams<{ jobId: string }>();

  useEffect(() => {
    const authStateListener = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        console.log('uid', user.uid);
      } else {
        console.log('user is logged out');
      }
    });

    return () => {
      authStateListener();
    };
  }, []);

  const handleDisconnect = () => {
    const fetchParticipantCount = async () => {
      try {
        const response = await fetch(`/room-participants/${room?.name}`);
        const data = await response.json();
        const parsedData = JSON.parse(data);
        console.log('People left: ' + parsedData.count);

        //If no one left in the room, let's create a recording (composition)
        if (parsedData.count == 0) {
          const createComposition = async () => {
            try {
              console.log('Room sid: ' + room?.sid);
              const response = await fetch(`/createComposition/${room?.sid}`);
              const data = await response.json();
              const parsedData = JSON.parse(data);

              saveRecordedLivestream(parsedData.compSid);
            } catch (error) {
              console.error('Failed to create composition', error);
            }
          };
          createComposition();
        }
      } catch (error) {
        console.error('Failed to fetch participant count', error);
      }
    };
    room!.disconnect();
    fetchParticipantCount();
  };

  const saveRecordedLivestream = async (compositionId: string) => {
    console.log('Client compSid: ' + compositionId);
    const videoURL = `https://ryan-twilio-video-bucket.s3.amazonaws.com/${compositionId}.mp4`;
    const feedCollection = collection(db, 'feed');

    const feedItem = {
      jobId: jobId,
      userId: user?.uid,
      type: 'videoLivestream',
      url: videoURL,
      timestamp: Timestamp.fromDate(new Date()),
    };

    await addDoc(feedCollection, feedItem);
  };

  return (
    <Button onClick={() => handleDisconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
