import { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ChaoticOrbit } from '@uiball/loaders';
import axios from 'axios';
import { RESIZE_IMAGE_WIDTH } from '../../constants';
import { db } from '../../services/firebase.config';
import { doc, updateDoc } from 'firebase/firestore';

const useStyles = makeStyles((theme: Theme) => ({
  centerLoader: {
    display: 'flex',
    justifyContent: 'center',
  },
  OCR_openAI: {
    margin: '25px 0 10px 0',
    '& img': {
      width: '25px',
      display: 'inline',
    },
    '& h5': {
      marginTop: '1em',
    },
  },
}));

type OCRProps = {
  itemID: string;
  imageURL: string;
  content: string;
};

function OCR_openAI({ itemID, imageURL, content }: OCRProps) {
  const [chatGPTOCR, setChatGPTOCR] = useState<string>(content);
  const [processing, setProcessing] = useState<boolean>(false);

  const classes = useStyles();

  const handleProcessDisplayedImage = async () => {
    const substring = 's3.amazonaws.com/';
    const index = imageURL.indexOf(substring);
    const fileName = encodeURIComponent(imageURL.substring(index + substring.length));
    const resizedFileName = getResizedFileName(fileName);

    const bucketName = encodeURIComponent('blue-stream-files');

    var theUrl = `/sendImageToOpenAI?bucketName=${bucketName}&fileName=${resizedFileName}`;
    setProcessing(true);
    var response = await axios.get(theUrl);
    const message = response.data.message;

    console.log(message);

    // Firestore document reference
    const feedRef = doc(db, 'feed', itemID);

    try {
      // Update Firestore document
      await updateDoc(feedRef, { content: message });
      console.log('Firestore updated successfully');
    } catch (error) {
      console.error('Error updating Firestore: ', error);
    }
    setChatGPTOCR(message);
    setProcessing(false);
  };

  function getResizedFileName(fileName: string) {
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtension = fileName.slice(lastDotIndex);
    return fileName.slice(0, lastDotIndex) + '_w' + RESIZE_IMAGE_WIDTH + fileExtension;
  }

  return (
    <div className={classes.OCR_openAI}>
      {processing ? (
        <div className={classes.centerLoader}>
          <ChaoticOrbit size={35} color="#f50057" />
        </div>
      ) : chatGPTOCR ? (
        <>
          <h5>Image Analysis Results:</h5>
          {chatGPTOCR.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </>
      ) : (
        <div onClick={handleProcessDisplayedImage}>
          <img
            style={{ display: 'inline' }}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/768px-ChatGPT_logo.svg.png"
          />{' '}
          Analyze Image
        </div>
      )}
    </div>
  );
}

export default OCR_openAI;
